<template>
  <div class="ip-telephony-child fs28">
    <bread-crumb v-if="data" :selected="data.name"></bread-crumb>
    <!-- вторая вложенность -->
    <div
      class="ip-telephony-child__two container container__mini"
      v-if="childrens.length > 0"
    >
      <div class="ip-telephony-child__two-wrap">
        <Cart
          v-for="children in childrens"
          :data="children"
          :key="children.id"
        />
      </div>
    </div>

    <!-- третья вложенность -->
    <div
      class="ip-telephony-child__three container container__mini"
      v-if="products.length > 0"
    >
      <div class="ip-telephony-child__three-title">{{ data.name }}</div>
      <div class="ip-telephony-child__three-wrap">
        <Cart2
          :productlist="products"
          @selectProduct="selectProduct"
          v-for="product in products"
          :data="product"
          :key="product.id"
        />
        <!-- если карточек больше 9 то показывать ссылку -->
        <div class="ip-telephony-child__three-more">
          <a class="ip-telephony-child__three-more-link" href="#"
            >Показати ще</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";

const BreadCrumb = defineAsyncComponent(() =>
  import("@/components/BreadCrumb")
);
const Cart = defineAsyncComponent(() => import("@/components/Cart.vue"));
const Cart2 = defineAsyncComponent(() => import("@/components/Cart2.vue"));
import axios from "axios";

export default {
  name: "IpTelephonyChildren",
  components: {
    BreadCrumb,
    Cart,
    Cart2,
  },
  watch: {
    data: function (newData) {
      //проверка на пустоту объекта
      if (Object.keys(newData).length !== 0) {
        window.mitt.emit("childrenData", this.childrenData);
      }
    },
  },
  data() {
    return {
      locale: this.$i18n.locale,
      childrens: [],
      products: [],
      product: [],
      data: [],
      childrenData: {
        image: "",
        icon: "",
        title: "",
      },
    };
  },
  methods: {
    selectProduct(product) {
      this.products = [];
      this.product = product;
    },
    async getService() {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        const response = await axios.get(
          process.env.VUE_APP_API +
            "/api/" +
            this.locale +
            "/services/search/" +
            this.$route.params.children,
          config
        );
        this.data = response.data.data[0];
        this.childrens = response.data.data[0].children;
        this.products = response.data.data[0].products;
      } catch (e) {
        alert("Ошибка запросса");
      } finally {
        //что-то делаем после завершения
      }
    },
    async getBanner() {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        const response = await axios.get(
          process.env.VUE_APP_API +
            "/api/" +
            this.locale +
            "/banners/search/" +
            this.$route.params.children,
          config
        );
        this.childrenData.image = response.data.data[0].image;
        this.childrenData.icon = response.data.data[0].icon[0].url;
        this.childrenData.title = response.data.data[0].title;
      } catch (e) {
        // alert("Ошибка запросса");
      } finally {
        //что-то делаем после завершения
      }
    },
  },
  created() {
    this.getService();
    this.getBanner();
  },
  updated() {
    document.title = this.data.name + " - iIT Trading";
    window.mitt.emit("childrenData", this.childrenData);
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/_mixins.scss";

.ip-telephony-child {
  &__three {
    &-wrap {
      display: grid;
      justify-content: center;
      grid-template-columns: minmax(270px, 360px);
      @include property("grid-column-gap", 143, 40, true, 1670, 1024);
      @include property("grid-row-gap", 80, 40, true, 1670, 1024);
      padding: 0 15px;
      @include property("margin-bottom", 100, 30);

      @media (min-width: 1024px) {
        grid-template-columns: repeat(3, 1fr);
      }

      @media (min-width: 700px) and (max-width: 1023.98px) {
        grid-template-columns: repeat(2, minmax(290px, 360px));
        justify-content: space-evenly;
      }
    }

    &-title {
      text-align: center;
      font-weight: 700;
      @include property("font-size", 42, 26, true, 1024, 320);
      line-height: 1;
      color: #000000;
      text-transform: uppercase;
      margin: 50px 0;
    }

    &-more {
      display: flex;
      justify-content: flex-end;
      @include property("margin-top", -30, -15, true, 1670, 1024);

      @media (min-width: 1024px) {
        grid-column: 3 span;
      }

      @media (min-width: 700px) and (max-width: 1023.98px) {
        grid-column: 2 span;
      }

      &-link {
        line-height: calc(30 / 16);
        letter-spacing: 2.4px;
        color: #f9b80e;
      }
    }
  }
}
</style>
